import React, { FC } from 'react';
import Typography from '../Typography';
import style from './OpportunityCard.module.scss';

interface IProps {
  imageSrc: string;
  title: string;
  text: string;
}

export const OpportunityCard: FC<IProps> = ({ imageSrc, title, text }) => (
  <div className={style.card}>
    <div className={style.imageWrapper}>
      <img
        className={style.image}
        src={imageSrc}
        alt=""
      />
    </div>
    <div>
      <Typography.P className={style.title}>{title}</Typography.P>
      <Typography.P>{text}</Typography.P>
    </div>
  </div>
);
