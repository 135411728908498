import React, { FC } from 'react';
import { Element as ScrollElement } from 'react-scroll';
import { Container } from '../Container';
import { Anchors } from '../Header';
import { OpportunityCard } from '../OpportunityCard';
import { Section } from '../Section';
import Typography from '../Typography';

import style from './Opportunities.module.scss';
import { opportunitiesData } from './opportunitiesData';

export const Opportunities: FC = () => (
  <ScrollElement name={Anchors.opportunities}>
    <Section color="gray">
      <Container>
        <Typography.H2 className={style.title}>
          Ваше искусство в формате VR. <span>В любом месте. В любое время.</span>
        </Typography.H2>
        <div className={style.opportunitiesList}>
          {opportunitiesData.map(({ id, title, text, imageSrc }) => (
            <OpportunityCard
              key={id}
              imageSrc={imageSrc}
              title={title}
              text={text}
            />
          ))
          }
        </div>
      </Container>
    </Section>
  </ScrollElement>
);
