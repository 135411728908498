import { FC, PropsWithChildren } from 'react';
import { Footer } from '../Footer';
import { Header } from '../Header';

import userImage from '../../assets/images/user-image.png';
import style from './Layout.module.scss';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={style.wrapper}>
      <aside className={style.sidebar}>
        <img src={userImage} alt="user" width={48} height={48} />
      </aside>

      <Header />
      <main className={style.main}>
        {children}
      </main>
      <Footer />
    </div>
  );
};
