import React, { FC, HTMLProps } from 'react';
import classNames from 'classnames';
import style from './Typography.module.scss';


export const H1: FC<HTMLProps<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <h1 {...props} className={classNames(style.h1, className)}>{children}</h1>
);

export const H2: FC<HTMLProps<HTMLHeadingElement>> = ({ children, className, ...props }) => (
  <h2 {...props} className={classNames(style.h2, className)}>{children}</h2>
);

export const P: FC<HTMLProps<HTMLParagraphElement>> = ({children, className, ...props }) => (
  <p {...props} className={classNames(style.p, className)}>{children}</p>
);
