import classNames from 'classnames';
import { FC } from 'react';
import logoJpg from '../../assets/images/logo.png';
import style from './BrandLogo.module.scss';

interface IProps {
  className?: string;
  variant?: 'primary' | 'secondary';
  showLogoImage?: 'always' | 'mobile-only';
}

export const BrandLogo: FC<IProps> = ({ className, variant = 'primary', showLogoImage = 'always' }) => (
  <p className={classNames(className, style.brand, variant === 'secondary' && style.brand_secondary)}>
    <span className={classNames(style.logo, showLogoImage === 'mobile-only' && style.logo_mobileOnly)}>
      <img 
        src={logoJpg}
        alt="logo"
      />
    </span>
    Art<span>Verse</span>
  </p>
);
