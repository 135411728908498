export const opportunitiesData = [
  {
    id: 0,
    imageSrc: require('../../assets/images/opportunities/uniqueness.png'),
    title: 'Уникальность',
    text: 'Мы создадим виртуальное пространство специально для вас и ваших произведений, а также добавим кнопки, окна и другие элементы, если необходимо',
  },
  {
    id: 1,
    imageSrc: require('../../assets/images/opportunities/full-immersion.png'),
    title: 'Полное погружение',
    text: 'Мы используем реалистичные текстуры и освещение, поэтому пользователи по-настоящему погружаются в атмосферу искусства, а не просто листают «картинки»',
  },
  {
    id: 2,
    imageSrc: require('../../assets/images/opportunities/all-devices.png'),
    title: 'Доступно на всех устройствах',
    text: 'Наши виртуальные галереи оптимизированы для работы в популярных браузерах на ПК, iOS и Android',
  },
  {
    id: 3,
    imageSrc: require('../../assets/images/opportunities/convenient-management.png'),
    title: 'Удобное управление',
    text: 'Мы предусмотрели несколько способов управления и навигации, чтобы вам было удобно наслаждаться искусством на любом устройстве',
  },
  {
    id: 4,
    imageSrc: require('../../assets/images/opportunities/not-only-paintings.png'),
    title: 'Не только картины',
    text: 'Наша платформа полноценно поддерживает воспроизведение 3D-объектов в разных режимах просмотра',
  },
  {
    id: 5,
    imageSrc: require('../../assets/images/opportunities/guided-tour.png'),
    title: 'Тур с гидом',
    text: 'К каждому произведению можно добавить звуковой файл. Приложение последовательно воспроизведет картины с голосовым комментарием по желанию пользователя',
  },
];
