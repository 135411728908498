import React, { FC, memo, useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import { Container } from '../Container';
import { Anchors } from '../Header';
import Typography from '../Typography';
import { BrandLogo } from '../BrandLogo';
import { RouteLinks } from '../../Pages/routes';

import { contactLinks } from '../Contacts';
import { configureHashLinkCreator, scrollToElement } from '../../utils/utils';
import style from './Footer.module.scss';


const _Footer: FC = () => {
  const location = useLocation();
  const isHomePage = useMemo(() => location.pathname === '/', [location.pathname]);
  const handleMenuItemClick = useCallback((anchor: Anchors) => {
    isHomePage && scrollToElement(anchor);
  }, [isHomePage]);

  return (
    <footer className={style.footer}>
      <section>
        <Container>
          <div className={style.content}>
            <div className={style.sectionsWrapper}>

              <div className={style.menuSection}>
                <BrandLogo className={style.brand} showLogoImage="mobile-only" />

                <ul className={style.menu}>
                  <li className={style.menu__item}>
                    <Link
                      to={configureHashLink(Anchors.whaWeAre)}
                      onClick={() => handleMenuItemClick(Anchors.whaWeAre)}
                    >
                      <Typography.P>Кто мы</Typography.P>
                    </Link>
                  </li>
                  <li className={style.menu__item}>
                    <Link
                      to={configureHashLink(Anchors.galleries)}
                      onClick={() => handleMenuItemClick(Anchors.galleries)}
                    >
                      <Typography.P>Галереи</Typography.P>
                    </Link>
                  </li>
                  <li className={style.menu__item}>
                    <Link
                      to={configureHashLink(Anchors.opportunities)}
                      onClick={() => handleMenuItemClick(Anchors.opportunities)}
                    >
                      <Typography.P>Возможности</Typography.P>
                    </Link>
                  </li>
                  <li className={style.menu__item}>
                    <Link
                      to={configureHashLink(Anchors.contacts)}
                      onClick={() => handleMenuItemClick(Anchors.contacts)}
                    >
                      <Typography.P>Контакты</Typography.P>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className={style.social}>
                <ul className={style.socialLinks}>
                  <li className={style.socialLinks__item}>
                    <a href={contactLinks.telegram} target="_blank" rel="noreferrer"><Typography.P>Telegram</Typography.P></a>
                  </li>
                  <li className={style.socialLinks__item}>
                    <a href={contactLinks.facebook} target="_blank" rel="noreferrer"><Typography.P>Facebook*</Typography.P></a>
                  </li>
                  <li className={style.socialLinks__item}>
                    <a href={contactLinks.instagram} target="_blank" rel="noreferrer"><Typography.P>Instagram*</Typography.P></a>
                  </li>
                </ul>

                <p className={style.note}>*Соцсети признаны экстремистскими и запрещены на территории РФ.</p>
              </div>

            </div>
          </div>
        </Container>
      </section>
      <section className={style.rightsRow}>
        <Container>
          <div className={style.rightsRow__content}>
            <p className={style.rightReserved}>©{new Date().getFullYear()} ArtVerse. All rights reserved.</p>
            <Link
              className={style.rightsRow__privacyPolicyLink}
              to={RouteLinks.PrivacyPolicy}
              onClick={() => animateScroll.scrollToTop({ duration: 0 })}
            >
              Политика конфиденциальности
            </Link>
          </div>
        </Container>
      </section>
    </footer>
  );
};

export const Footer = memo(_Footer);

const configureHashLink = configureHashLinkCreator(RouteLinks.Home);
