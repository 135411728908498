import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import style from './Container.module.scss';

interface IProps {
  children: ReactNode;
  className?: string;
}

export const Container: FC<IProps> = ({ className, children }) => {

  return (
    <div className={classNames(style.container, className)}>
      {children}
    </div>  
  );
};
