import { Link } from 'react-router-dom';
import { RouteLinks } from '../routes';
import style from './Error404.module.scss';

export const Error404 = () => (
  <main className={style.content}>
    <p className={style.title}><span>Art</span>Verse 404</p>
    <Link className={style.link} to={RouteLinks.Home}>Вернуться на главную</Link>
  </main>
);
