import React, { FC, ReactNode, useContext } from 'react';
import classNames from 'classnames';
import { AppContext } from '../../App';
import { ReactComponent as FlagRu } from '../../assets/images/flag-ru.svg';
import style from './LanguageSelector.module.scss';

interface IProps {
  withFlag?: boolean
  className?: string
}

export enum Languages {
  ru,
  en
}

const flags: {[key in Languages]: ReactNode} = {
  [Languages.ru]: <FlagRu width={28} height={20} />,
  [Languages.en]: <p>en</p>,
};

export const LanguageSelector: FC<IProps> = ({ withFlag = false, className }) => {
  const { language, handleLanguageChange } = useContext(AppContext);


  return (
    <div className={classNames(style.languageSection, className)}>
      <button
        className={classNames(
          style.languageButton,
          language === Languages.ru && style.languageButton_selected,
        )}
        onClick={() => handleLanguageChange(Languages.ru)}
      >RU
      </button>
      <span className={style.divider}></span>
      <button
        className={classNames(
          style.languageButton,
          language === Languages.en && style.languageButton_selected,
        )}
        onClick={() => handleLanguageChange(Languages.en)}>EN</button>
      {withFlag && flags[language]}
    </div>
  );
};
