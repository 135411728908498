import { RouteLinks } from '../../Pages/routes';
import { IGalleryData } from './Galleries';

export const galleriesData: Array<IGalleryData> = [
  {
    galleryName: 'Хрупкие моменты',
    artistName: 'Белла Новинькова',
    imgSrc: require('../../assets/images/galleries-preview/moments.png'),
    link: RouteLinks.Moments,
  },
  {
    galleryName: 'Коробка с открытками',
    artistName: 'Виктория Ситникова',
    imgSrc: require('../../assets/images/galleries-preview/postcards.png'),
    link: RouteLinks.Postcards,
  },
  {
    galleryName: 'Музей истории ислама России',
    artistName: 'Издательский дом «Медина»',
    imgSrc: require('../../assets/images/galleries-preview/medina.png'),
    link: RouteLinks.Medina,
  },
];
