import { scroller } from 'react-scroll';
import { Anchors } from '../Components/Header';
import { RouteLinks } from '../Pages/routes';

export const configureHashLinkCreator = (path: RouteLinks) => (anchor: Anchors) => {
  return `${path}#${anchor}`;
};

export const scrollToElement = (element: string) => {
  scroller.scrollTo(
    element,
    {
      offset: -90,
      duration: 300,
      smooth: true
    });
};
