import React from 'react';
import { Container } from '../Container';
import { Anchors } from '../Header';
import Typography from '../Typography';
import { Section } from '../Section';
import { Element as ScrollElement } from 'react-scroll';

import style from './WhoWeAre.module.scss';

export const WhoWeAre = () => {
  
  return (
    <ScrollElement name={Anchors.whaWeAre}>
      <Section className={style.section}>
        <Container>
          <div className={style.row}>
            <Typography.H1 className={style.title}>
            Платформа для <span>создания</span> и просмотра виртуальных галерей
            </Typography.H1>

            <div className={style.info}>
              <Typography.P>
                ArtVerse – новое слово в мире презентации искусства и мультимедиа. Благодаря VR-технологиям, мы помогаем художникам стать ближе к своей аудитории.
              </Typography.P>
              <Typography.P>
                Создайте свою виртуальную галерею, и ваши произведения увидят еще больше зрителей.
              </Typography.P>
              <Typography.P>
                В любом месте. В любое время.
              </Typography.P>
            </div>
          </div>
        </Container>
      </Section>
    </ScrollElement>
  );
};
