import { createContext, Suspense, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { Languages } from './Components/LanguageSelector';
import { Layout } from './Components/Layout/Layout';
import { Error404 } from './Pages/404/Error404';
import { Home } from './Pages/Home';
import { PrivacyPolicy } from './Pages/PrivacyPolicy';
import { RouteLinks } from './Pages/routes';
import './styles/index.scss';

interface IAppContext {
  language: Languages,
  handleLanguageChange: (language: Languages) => void,
}

export const AppContext = createContext<IAppContext>({
  language: Languages.ru,
  handleLanguageChange: () => {},
});

function App() {
  const [language, setLanguage] = useState<Languages>(Languages.ru);
  const handleLanguageChange = (language: Languages) => {
    setLanguage(language);
  };

  return (
    <AppContext.Provider
      value={{
        language: language,
        handleLanguageChange: handleLanguageChange,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route element={
            <Layout>
              <Suspense fallback={null}>
                <Outlet />
              </Suspense>
            </Layout>
          }>
            <Route path={RouteLinks.Home} element={<Home />} />
            <Route path={RouteLinks.PrivacyPolicy} element={<PrivacyPolicy />} />
          </Route>
          <Route path="/*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
