import { IImage } from './CarouselSection';

export const images: Array<IImage> = [
  {
    id: 'carousel-image-1',
    src: require('../../assets/images/carousel/0.webp'),
    alt: 'Gallery image'
  },
  {
    id: 'carousel-image-2',
    src: require('../../assets/images/carousel/1.webp'),
    alt: 'Gallery image'
  },
  {
    id: 'carousel-image-3',
    src: require('../../assets/images/carousel/2.webp'),
    alt: 'Gallery image'
  },
  {
    id: 'carousel-image-4',
    src: require('../../assets/images/carousel/3.webp'),
    alt: 'Gallery image'
  },
  {
    id: 'carousel-image-5',
    src: require('../../assets/images/carousel/4.webp'),
    alt: 'Gallery image'
  },
];
