import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import style from './Section.module.scss';

interface IProps {
  children: ReactNode
  color?: 'white' | 'gray'
  className?: string
}

export const Section: FC<IProps> = ({ children, color = 'white', className }) => ( 
  <section className={classNames(className, style.section, color==='gray' && style.section_gray)}>
    {children}
  </section>
);
