import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Container } from '../Container';
import style from './Header.module.scss';
import { LanguageSelector } from '../LanguageSelector';
import { BurgerButton } from './BurgerButton';
import classNames from 'classnames';
import { BrandLogo } from '../BrandLogo';
import { Link, useLocation } from 'react-router-dom';
import { RouteLinks } from '../../Pages/routes';
import { configureHashLinkCreator, scrollToElement } from '../../utils/utils';

export enum Anchors {
  whaWeAre = 'who-we-are',
  galleries = 'galleries',
  opportunities = 'opportunities',
  contacts = 'contacts'
}

const _Header: FC = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isHomePage = useMemo(() => location.pathname === '/', [location.pathname]);
  const handleMenuItemClick = useCallback((anchor: Anchors) => {
    isMenuOpen && setIsMenuOpen(false);
    isHomePage && scrollToElement(anchor);
  }, [isMenuOpen, isHomePage]);
  
  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
  }, [isMenuOpen]);

  return (
    <header className={style.header}>
      <Container>
        <div className={style.content}>
          <Link to={RouteLinks.Home}>
            <BrandLogo className={style.brand} variant={isMenuOpen ? 'primary': 'secondary'} />
          </Link>
          <ul className={classNames(style.menu, isMenuOpen && style.menu_open)}>
            <li className={style.menu__item}>
              <Link
                to={configureHashLink(Anchors.whaWeAre)}
                onClick={() => handleMenuItemClick(Anchors.whaWeAre)}
              >
                Кто мы
              </Link>
            </li>
            <li className={style.menu__item}>
              <Link
                to={configureHashLink(Anchors.galleries)}
                onClick={() => handleMenuItemClick(Anchors.galleries)}
              >
                Галереи
              </Link>
            </li>
            <li className={style.menu__item}>
              <Link
                to={configureHashLink(Anchors.opportunities)}
                onClick={() => handleMenuItemClick(Anchors.opportunities)}
              >
                Возможности
              </Link>
            </li>
            <li className={style.menu__item}>
              <Link
                to={configureHashLink(Anchors.contacts)}
                onClick={() => handleMenuItemClick(Anchors.contacts)}
              >
                Контакты
              </Link>
            </li>
            {false && <LanguageSelector className={style.languageSelector_mobile} /> /* Временно скрыто */}
          </ul>
          {false && <LanguageSelector className={style.languageSelector_desktop} /> /* Временно скрыто */}
          
          <BurgerButton
            isMenuOpen={isMenuOpen}
            onClick={() => setIsMenuOpen((state) => !state)}
          />
        </div>
      </Container>
    </header>
  );
};

export const Header = memo(_Header);

const configureHashLink = configureHashLinkCreator(RouteLinks.Home);
