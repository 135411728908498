import React, { FC } from 'react';
import { Element as ScrollElement } from 'react-scroll';
import classNames from 'classnames';
import { Container } from '../Container';
import { Section } from '../Section';
import Typography from '../Typography';
import { Anchors } from '../Header';

import { ReactComponent as MessageIcon } from '../../assets/images/message.svg';
import { ReactComponent as PhoneIcon } from '../../assets/images/phone.svg';
import { ReactComponent as MapMarkerIcon } from '../../assets/images/map-marker.svg';
import { ReactComponent as TelegramIcon } from '../../assets/images/telegram.svg';
import { ReactComponent as FaceBookIcon } from '../../assets/images/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/instagram.svg';
import CoworkingImage from '../../assets/images/coworking.png';
import style from './Contacts.module.scss';

export const contactLinks = {
  telegram: 'https://t.me/novinkoff',
  facebook: 'https://www.facebook.com/profile.php?id=100088333809267',
  instagram: 'https://instagram.com/the_artverse_gallery',
};

export const Contacts: FC = () => (
  <ScrollElement name={Anchors.contacts}>
    <Section>
      <Container>
        <div className={style.card}>
          <div className={style.row}>
            <div className={style.info}>
              <Typography.H2 className={style.title}>Контакты</Typography.H2>
              <div className={classNames(style.row, style.row_info)}>
                <div className={style.column}>
                  <ul>
                    <li className={style.contactRow}>
                      <span className={style.iconWrapper}><MessageIcon /></span>
                      <a href="mailto:info@theartverse.gallery"><Typography.P>info@theartverse.gallery</Typography.P></a>
                    </li>
                    <li className={style.contactRow}>
                      <span className={style.iconWrapper}><PhoneIcon /></span>
                      <a href="tel:+79154563511"><Typography.P>+7 (915) 456-35-11</Typography.P></a>
                    </li>
                    <li className={style.contactRow}>
                      <span className={style.iconWrapper}><MapMarkerIcon /></span>
                      <Typography.P>г. Москва</Typography.P>
                    </li>
                  </ul>                
                  {/* <LinkButton className={style.button}>Узнать больше</LinkButton> */}
                </div>
                <div className={style.social}>
                  <Typography.P className={style.subtitle}>Социальные сети:</Typography.P>
                  <div className={style.social__links}>
                    <a className={style.iconWrapper} href={contactLinks.telegram} target="_blank" rel="noreferrer"><TelegramIcon /></a>
                    <a 
                      className={classNames(style.iconWrapper, style.iconWrapperBanned)}
                      href={contactLinks.facebook}
                      target="_blank"
                      rel="noreferrer"
                    ><FaceBookIcon /></a>
                    <a
                      className={classNames(style.iconWrapper, style.iconWrapperBanned)}
                      href={contactLinks.instagram}
                      target="_blank"
                      rel="noreferrer"
                    ><InstagramIcon /></a>
                  </div>
                  <Typography.P className={style.note}>*Соцсети признаны экстремистскими и запрещены на территории РФ.</Typography.P>
                </div>
              </div>
            </div>
            <div className={style.imageWrapper}>
              <div className={style.imageContainer}>
                <img className={style.image} src={CoworkingImage} alt="Coworking" />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  </ScrollElement>
);
