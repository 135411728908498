import React, { FC, useCallback } from 'react';
import Carousel, { ControlProps } from 'nuka-carousel';

import style from './CarouselSection.module.scss';
import classNames from 'classnames';

export interface IImage {
  id: string
  src: string
  alt: string
}

interface IProps {
  images: Array<IImage>
}

export const CarouselSection: FC<IProps> = ({ images }) => {
  const renderBullets = useCallback(({ currentSlide }: ControlProps) => (
    <div className={style.bulletsWrapper}>
      {images.map(({ id }, index) => (
        <span
          key={`bullet-${id}`}
          className={classNames(style.bullet, index === currentSlide && style.bullet_active)}
        ></span>
      ))}
    </div>
  ), [images]);

  return (
    <Carousel
      autoplay
      wrapAround
      pauseOnHover
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
      renderBottomCenterControls={null}
      renderBottomRightControls={renderBullets}
    >
      {images.map(({ id, src, alt }) => (
        <div key={id} className={style.imageWrapper}>
          <img className={style.image} src={src} alt={alt} />
        </div>
      ))}
    </Carousel>
  );
};
