import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { Element as ScrollElement } from 'react-scroll';
import { Button } from '../Button';
import { Container } from '../Container';
import { GalleryCard, GalleryCardSize } from '../GalleryCard';
import { Anchors } from '../Header';
import { Section } from '../Section';
import Typography from '../Typography';
import style from './Galleries.module.scss';

export interface IGalleryData {
  galleryName: string
  artistName: string
  imgSrc: string
  link: string;
}

interface IProps {
  galleriesData: Array<IGalleryData>
}

const initialShownGalleriesCount = 5;

const getGalleryCardSize = (cardIndex: number, totalNumberOfCards: number): GalleryCardSize => {
  switch (totalNumberOfCards) {
    case 1:
    case 2:
    case 3:
      return 'big';
    case 4:
      return cardIndex < 2 ? 'big' : 'normal';
    default:
      return cardIndex === 0 ? 'big' : 'normal';
  }
};

export const Galleries: FC<IProps> = ({ galleriesData }) => {
  const [isAllGalleriesShown, setIsAllGalleriesShown] = useState(false);

  return (
    <ScrollElement name={Anchors.galleries}>
      <Section className={style.section}>
        <Container>
          <Typography.H2 className={style.title}>Галереи</Typography.H2>
          <div className={style.galleriesList}>
            {galleriesData.map(({ imgSrc, galleryName, artistName, link }, index) => {
              if (!isAllGalleriesShown && index >= initialShownGalleriesCount) return null;
              const galleryCardSize = getGalleryCardSize(index, galleriesData.length);

              return (
                <GalleryCard
                  key={galleryName}
                  imageSrc={imgSrc}
                  galleryName={galleryName}
                  artistName={artistName}
                  link={link}
                  size={galleryCardSize}
                  className={classNames(galleryCardSize === 'big' && style.bigGalleryCard)}
                />
              );
            })}
          </div>
          {!isAllGalleriesShown && galleriesData.length > initialShownGalleriesCount && (
            <Button
              className={style.button}
              onClick={() => setIsAllGalleriesShown(true)}
            >
              Посмотреть все
            </Button>
          )}
        </Container>
      </Section>
    </ScrollElement>
  );
};
