import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import style from './Button.module.scss';

export const Button: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children, className, ...props
}) => (
  <button {...props} className={classNames(className, style.button)}>
    {children}
  </button>
);

export const LinkButton: FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({ href, children, className, ...props}) => (
  <Link {...props} to={href || ''} className={classNames(className, style.button)}>
    {children}
  </Link>
);
