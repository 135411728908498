import classNames from 'classnames';
import React, { FC } from 'react';

import style from './BurgerButton.module.scss';

interface IPtops {
  isMenuOpen: boolean
  onClick: () => void
}

export const BurgerButton: FC<IPtops> = ({ isMenuOpen, onClick }) => (
  <button className={style.burgerButton} onClick={onClick}>
    <div className={classNames(style.burger, isMenuOpen && style.burger_open)}><span /></div>
  </button>
);
