import React, { FC } from 'react';
import { ReactComponent as EyeSvg } from '../../assets/images/eye.svg';
import style from './GalleryCard.module.scss';
import classNames from 'classnames';

export type GalleryCardSize = 'normal' | 'big';

interface IProps {
  imageSrc: string;
  galleryName: string;
  artistName: string;
  link: string
  size?: GalleryCardSize;
  className?: string;
}

export const GalleryCard: FC<IProps> = ({ imageSrc, galleryName, artistName, size, className, link }) => (
  <a className={classNames(className, style.wrapper)} href={link}>
    <div className={classNames(style.content, size === 'big' && style.content_big)}>
      <img className={style.image} src={imageSrc} alt="" />
      <div className={style.info}>
        <p className={style.galleryName}>{galleryName}</p>
        <p className={style.artistName}>{artistName}</p>
        <span className={style.eyeWrapper}><EyeSvg /></span>
      </div>
    </div>
  </a>
);
