import React, { FC, useEffect } from 'react';
import { WhoWeAre } from '../../Components/WhoWeAre';
import { Galleries } from '../../Components/Galleries';
import { Contacts } from '../../Components/Contacts';
import { Opportunities } from '../../Components/Opportunities';
import { CarouselSection } from '../../Components/CarouselSection';
import { images } from '../../Components/CarouselSection/images';
import { galleriesData } from '../../Components/Galleries/galleriesData';
import { useLocation } from 'react-router-dom';
import { scrollToElement } from '../../utils/utils';

export const Home: FC = () => {
  const location = useLocation();

  useEffect(() => {
    scrollToElement(location.hash.slice(1));
  }, [location.hash]);

  return (
    <>
      <CarouselSection images={images} />
      <WhoWeAre />
      <Galleries galleriesData={galleriesData} />
      <Opportunities />
      <Contacts />
    </>
  );
};
