import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { Contacts } from '../../Components/Contacts';
import { Container } from '../../Components/Container';
import Typography from '../../Components/Typography';
import style from './PrivacyPolicy.module.scss';

export const PrivacyPolicy: FC = () => {
  return ( 
    <>
      <Container className={style.container}>
        <div className={style.content}>
          <Typography.H1 className={style.mainTitle}>Политика конфиденциальности</Typography.H1>
          <OrderedList>
            <ListItem marked>
              <Point marked>
                Общие положения
              </Point>

              <OrderedList>
                <ListItem>
                  <Point>
                    Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных Индивидуальным предпринимателем Новиньковым Павлом Викторовичем (далее - Оператор).
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Настоящая политика Оператора в отношении обработки персональных данных (далее - Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта theartverse.gallery.
                  </Point>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem marked>
              <Point marked>
                Основные понятия, используемые в Политике:
              </Point>

              <OrderedList>
                <ListItem>
                  <Point>
                    Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети Интернет по сетевому адресу https://www.theartverse.gallery (далее — Сайт);
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Персональные данные — любая информация, относящаяся к прямо или косвенно определенному или определяемому Пользователю Сайта;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Пользователь — любой посетитель Сайта;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Предоставление персональных данных — действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Распространение персональных данных — любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Уничтожение персональных данных — любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.
                  </Point>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem marked>
              <Point marked>
                Оператор может обрабатывать следующие персональные данные:
              </Point>

              <OrderedList>
                <ListItem>
                  <Point>
                    Адрес электронной почты Пользователя;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Номер мобильного телефона Пользователя;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Фамилия, имя Пользователя;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Аккаунт Пользователя в социальной сети;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Также на Сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Google Analytics и других);
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Вышеперечисленные данные далее по тексту Политики объединены общим понятием Персональные данные;
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    У Пользователя может быть запрошено разрешение на предоставление доступа к учетным записям сторонних Сервисов, включая, среди прочего, общедоступное хранилище файлов, службу мгновенных сообщений, социальные сети и т. д., в соответствии с целью обработки персональных данных, установленной настоящей Политикой. В этом случае Платформа может получать от третьих лиц дополнительные персональные данные, включая, но не ограничиваясь, пол, местоположение, аватар пользователя (userpic) и т. д. Вся информация, доступная через стороннего поставщика услуг, обрабатывается и хранится в соответствии с Пользовательским соглашением и Политикой конфиденциальности.
                  </Point>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem marked>
              <Point marked>
                Цели обработки персональных данных
              </Point>

              <OrderedList>
                <ListItem>
                  <Point>
                    Цель обработки персональных данных Пользователя — связь с Пользователем для предоставления услуг, указанных на Сайте.
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Обезличенные данные Пользователей, собранные с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на Сайте, улучшения качества Сайта и его содержания.
                  </Point>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem marked>
              <Point marked>Правовые основания обработки персональных данных</Point>

              <OrderedList>
                <ListItem>
                  <Point>
                    Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на Сайте. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и/или использование технологии JavaScript).
                  </Point>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem marked>
              <Point marked>
                Порядок сбора, хранения, передачи и других видов обработки персональных данных
              </Point>
              <br /><br />
              <Point>
                  Безопасность персональных данных, которые обрабатываются Оператором, обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
              </Point>

              <OrderedList>
                <ListItem>
                  <Point>
                    Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомления на адрес электронной почты Оператора info@theartverse.gallery с пометкой «Актуализация персональных данных».
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Срок обработки персональных данных определяется длительностью срока, необходимого для достижения цели обработки персональных данных, предусмотренной разделом 4 Политики.
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора info@theartverse.gallery с пометкой «Отзыв согласия на обработку персональных данных».
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Основные способы, которыми Оператор получает персональные данные от Пользователя:
                  </Point>
                  <br />
                  <Point>
                    а&#41; Пользователь предоставляет персональные данные напрямую (например, при оформлении заказа на виртуальный тур в специальной форме на Сайте);
                  </Point>
                  <br />
                  <Point>
                    б&#41; Персональные данные собираются автоматически, когда Пользователь просматривает или использует Сайт, например с помощью cookie-файлов. Cookie файлы — небольшой фрагмент текста, отправленный сервером и хранимый на компьютере Пользователя. Cookie выполняют множество функций, например, сохраняют настройки, сделанные Пользователем, позволяют перемещаться между страницами после авторизации и в целом упрощают работу с Сайтом.
                  </Point>
                  <br /><br />
                  <Point>
                    Оператор использует cookie файлы для:
                  </Point>
                  <br />
                  <Point>
                    а&#41; Идентификации — файлы cookie позволяют распознать устройство Пользователя;
                  </Point>
                  <br />
                  <Point>
                    б&#41; Статистики — файлы cookie позволяют собирать данные о просмотре тех или иных страниц.
                  </Point>
                  <br />
                  <Point>
                    Пользователь может запретить браузеру принимать cookie, но это существенно ограничит его возможности по использованию Платформы.
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Администрация может раскрывать персональные данные Пользователя в рамках закона или для защиты прав и интересов, если такое раскрытие является необходимым для соблюдения закона, защиты прав Оператор или третьих лиц, а также защиты собственности и/или интересов (например, соблюдения условий Пользовательского соглашения) или предотвращения мошенничества. В частности, Оператор может раскрыть персональные данные Пользователя в ответ на официальные запросы со стороны государственных органов, например, для удовлетворения требований национальной безопасности или правоохранительных органов.
                  </Point>
                  <br /><br />
                  <Point> 
                    Оператор может обмениваться персональными данными с третьими лицами, с целью предоставления Пользователю индивидуальной рекламы, анализа и контроля ее эффективности. Например, Оператор может использовать зашифрованный адрес электронной почты для настройки рекламы в социальной сети, чтобы не показывать рекламу лицам, которые уже являются Пользователями Сайта.
                  </Point>
                </ListItem>
              </OrderedList>
            </ListItem>

            <ListItem marked>
              <Point marked>
                Заключительные положения
              </Point>
              <OrderedList>
                <ListItem>
                  <Point>
                    В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
                  </Point>
                </ListItem>
                <ListItem>
                  <Point>
                    Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу{' '}
                    <a className={style.link} href="https://theartverse.gallery/privacy-policy/">https://theartverse.gallery/privacy-policy/</a>
                  </Point>
                </ListItem>
              </OrderedList>
            </ListItem>
          </OrderedList>
        </div>
      </Container>
      <Contacts />
    </>
  );
};

const OrderedList: FC<PropsWithChildren> = ({ children }) =>
  <ol className={style.orderedList}>{children}</ol>;

const ListItem: FC<PropsWithChildren<{ marked?: boolean; }>> = ({ children, marked }) =>
  <li className={classNames(style.listItem, marked && style.listItemMarked)}>{children}</li>;

const Point: FC<PropsWithChildren<{ marked?: boolean; }>> = ({ children, marked }) =>
  <Typography.P className={classNames(style.point, marked && style.pointMarked)}>{children}</Typography.P>;
